import * as types from './mutation-types'
import {
  httpRequest,
  httpRequestUpyun,
  httpRequestUpyunSign,
} from '@/utils/request'

export default {
  // 获取又拍云签名
  getUpYunSign ({ dispatch, commit, state }) {
    return new Promise((resolve) => {
      httpRequestUpyunSign.request({
        method: 'GET',
        url: '/support/get_upyun_sign'
      }).then((res) => {
        resolve(res)
      })
    })
  },
  // 获取APPKey
  getAppKey ({ dispatch, commit, state }) {
    return new Promise((resolve) => {
      httpRequest.request({
        method: 'GET',
        url: `/v2/interview_training/get_app_key`
      }).then((res) => {
        resolve(res.data)
      })
    })
  },
  // AI模拟面试分类列表
  getInterviewTrainType ({ dispatch, commit, state }) {
    return new Promise((resolve) => {
      httpRequest.request({
        method: 'GET',
        url: `/v2/interview_training/category`
      }).then((res) => {
        resolve(res.data)
      })
    })
  },
  // 获取分类面试题目列表
  getInterviewTrainLists ({ dispatch, commit, state }, data) {
    if (data.page === 1) commit(types.SET_INTERVIEW_TRAIN_LISTS, [])
    return new Promise((resolve) => {
      httpRequest({
        method: 'GET',
        url: `/v2/interview_training/list_by_cate?category_id=${data.category_id}&page=${data.page}&page_size=${data.page_size}`
      }).then((res) => {
        const obj = res.data
        let lists = JSON.parse(JSON.stringify(state.interviewTrainLists))
        commit(types.SET_INTERVIEW_TRAIN_LISTS, [...lists, ...obj.data])
        resolve(obj)
      })
    })
  },
  // 获取AI训练过渡页内容
  getNewTrainingBefore ({ dispatch, commit, state }, data) {
    return new Promise((resolve) => {
      httpRequest({
        method: 'GET',
        url: `/v2/interview_training/special?id=${data.id}`
      }).then((res) => {
        resolve(res.data)
      })
    })
  },
  // 面试训练内容
  getInterviewTrainingDetail ({ dispatch, commit, state }, data) {
    return new Promise((resolve) => {
      httpRequest({
        method: 'GET',
        url: `/v2/interview_training/${data.id}`
      }).then((res) => {
        resolve(res)
      })
    })
  },
  // 上传视频
  videoUpyun ({ dispatch, commit, state }, data) {
    return new Promise((resolve) => {
      httpRequestUpyun.request({
        method: 'POST',
        url: '/interview-tzkj',
        data
      }).then((res) => {
        resolve(res)
      })
    })
  },
  // 上传答题
  submitAnswer ({ dispatch, commit, state }, data) {
    return new Promise((resolve) => {
      httpRequest.request({
        method: 'POST',
        url: '/v2/interview_training/save_answer',
        data
      }).then((res) => {
        resolve(res.data)
      })
    })
  },
  // 面试记录列表
  getInterViewRecordLists ({ dispatch, commit, state }, data) {
    if (data.page === 1) commit(types.SET_INTERVIEW_RECORD_LISTS, [])
    return new Promise((resolve) => {
      httpRequest({
        method: 'POST',
        url: `/v2/interview_training/my_answer`,
        data
      }).then((res) => {
        const obj = res.data
        let lists = JSON.parse(JSON.stringify(state.interviewRecordLists))
        commit(types.SET_INTERVIEW_RECORD_LISTS, [...lists, ...obj.data])
        resolve(res.data)
      })
    })
  },
  // 面试报告详情
  getInterviewTrainDetail ({ dispatch, commit, state }, data) {
    return new Promise((resolve) => {
      httpRequest({
        method: 'POST',
        url: `/v2/interview_training/answer_detail`,
        data
      }).then((res) => {
        resolve(res.data)
      })
    })
  }
}
