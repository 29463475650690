<template>
  <div class="aiInterview-wrapper">
    <div class="content-center">
      <div
        @click="onSkipMyInterviewPageFn"
        class="top-banner"
      >
        <img src="@/assets/img/aiInterview-top-bg.png">
      </div>
      <div class="info-box">
        <div class="nav-box">
          <div class="nav-list">
            <div class="nav-ul">
              <div
                v-for="(item, index) in typeLists"
                @click.stop="onSelectNavFn(item.id, index)"
                class="item"
                :class="{ 'item-active' : topicListObj.typeIndex === index }"
                :key="index"
              >
                <div class="text">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-box">
          <div
            v-if="interviewTrainLists.length"
            class="ul-box"
          >
            <div
              v-for="(item, index) in interviewTrainLists"
              @click="onSkipPageFn(item)"
              class="item"
              :key="index"
            >
              <div class="cover" :style="{backgroundImage: `url(${item.cover})`}"></div>
              <div class="text-box">
                <div class="title">{{ item.title }}</div>
                <div v-if="item.intro" class="text">{{ item.intro }}</div>
                <div class="num-box">
                  <div class="num hot-num">热度 {{ item.people_num }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!interviewTrainLists.length && loadingText !== '加载中...'"
            class="no-data-show"
          >
            <div class="icon">
              <img src="@/assets/img/no-data-icon3.png" />
            </div>
            <div class="text">暂无模拟面试</div>
          </div>
          <div
            @click="onLoadingMoreFn"
            class="data-text"
          >{{ loadingText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      topicListObj: {
        typeIndex: 0, // AI模拟面试分类index
        category_id: '', // AI模拟面试分类id
        page_size: 10,
        page: 1
      },
      typeLists: [],
      loadingText: '加载中...'
    }
  },
  created () {
    this.getInterviewTrainTypeFn()
  },
  computed: {
    ...mapState([
      'interviewTrainLists'
    ])
  },
  methods: {
    ...mapActions([
      'getInterviewTrainType',
      'getInterviewTrainLists'
    ]),
    // 选择菜单栏
    onSelectNavFn (id, index) {
      this.topicListObj = {
        category_id: id || '',
        typeIndex: index,
        page_size: 10,
        page: 1
      }
      this.getInterviewTrainListsFn()
    },
    // AI模拟面试分类列表
    async getInterviewTrainTypeFn () {
      this.typeLists = await this.getInterviewTrainType()
      this.getInterviewTrainListsFn()
    },
    // 分类下AI模拟面试列表
    async getInterviewTrainListsFn () {
      this.loadingText = '加载中...'
      const obj = await this.getInterviewTrainLists(this.topicListObj)
      if (obj && obj.data.length === this.topicListObj.page_size) {
        this.loadingText = '点击加载更多'
      } else {
        this.loadingText = '没有更多数据'
      }
    },
    // 点击加载更多
    onLoadingMoreFn () {
      this.topicListObj.page += 1
      this.getInterviewTrainListsFn()
    },
    // 跳转页面
    onSkipPageFn (item) {
      this.$router.push({
        path: '/interview_detail',
        query: { id: item.id }
      })
    },
    // 跳转面试记录
    onSkipMyInterviewPageFn () {
      this.$router.push({
        path: '/my_interview'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .aiInterview-wrapper
    width: 100%
    height: auto
    img
      display: block
      width: 100%
      height: 100%
    .top-banner
      position: relative
      width: 100%
      height:480px
    .info-box
      width: 100%
      height: auto
      .nav-box
        height: 104px
        padding-left: 40px
        box-sizing: border-box
        margin: 30px 0 40px
        overflow: hidden
        .nav-list
          display: flex
          width: 100%
          height: 100%
          white-space: nowrap
          overflow: hidden
          overflow-x: auto
          -webkit-overflow-scrolling: touch
          .nav-ul
            display: flex
            width: auto
            height: auto
            .item
              display: inline-block
              line-height: 96px
              padding: 0 34px
              box-sizing: border-box
              margin-right: 30px
              overflow: hidden
              background: #F0F3F5
              font-size: 28px
              font-weight: 600
              text-align: center
              color: #9899AA
              border-radius: 16px
              .num
                font-size: 24px
                color: #BCBDD6
            .item-active
              background: #4B81FF
              color: #fff
              .num
                color: rgba(255, 255, 255, 0.5)
        .nav-list::-webkit-scrollbar
          width: 0
          height: 0
          color: transparent
      .list-box
        padding: 0 40px 60px
        box-sizing: border-box
        .ul-box
          width: 100%
          height: auto
          .item
            display: flex
            align-items: center
            width: 100%
            height: auto
            margin-bottom: 60px
            .cover
              width: 160px
              height: 200px
              background-position-x: right
              background-size: cover
              background-repeat: no-repeat
              border-radius: 16px
            .text-box
              position: relative
              flex: 1
              height: 200px
              padding: 8px 0 0 30px
              box-sizing: border-box
              .title
                font-size: 32px
                font-weight: 600
                color: #34271D
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 1
                word-break: break-all
                overflow: hidden
              .text
                margin-top: 12px
                font-size: 24px
                line-height: 30px
                color: #7B756F
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 2
                word-break: break-all
                overflow: hidden
              .num-box
                position: absolute
                bottom: 10px
                display: flex
                align-items: center
                .num
                  font-size: 24px
                  color: #34271D
                  &.hot-num
                    box-sizing: border-box
                    color: #7B756F
          .item:last-child
            margin-bottom: 0
        .data-text
          line-height: 120px
          text-align: center
          font-size: 30px
          color: #777777
</style>
