<template>
  <div class="interview-wrapper">
    <div class="content-center">
      <div
        class="return"
        @click="onBackFn"
      >
        <img src="@/assets/img/return-icon1.png" />
      </div>
      <div class="choose-box">
        <div class="type">
          <div class="item">
            <div class="text">面试记录</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div
        v-if="interviewRecordLists.length"
        class="list-ul"
      >
        <div
          class="item"
          v-for="(item, index) in interviewRecordLists"
          @click="onSkipPageFn(item)"
          :key="index"
        >
          <div class="top-nav">
            <div class="title">AI求职面试记录</div>
            <div class="icon">
              <img src="@/assets/img/more-btn5.png" />
            </div>
          </div>
          <div class="info-box">
            <div class="text-box">
              <div class="title">{{ item.info.title }}</div>
              <div class="date">{{ item.create_time }}</div>
            </div>
            <div class="cover-box">
              <img
                v-if="item.info.cover"
                :src="item.info.cover"
                class="cover"
              />
              <img
                v-else
                src="@/assets/img/my-train-bg.png"
                class="cover"
              />
              <div class="mark"></div>
              <div class="data-box">
                <div
                  v-if="item.is_counting === 1"
                  class="counting"
                >
                  <div class="circle">
                    <img src="@/assets/img/circle-icon1.png" />
                  </div>
                  <div class="text">评分中...</div>
                </div>
                <div v-else class="score">{{ item.total_score }}<span>分</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!interviewRecordLists.length && loadingText !== '加载中...'"
        class="no-data-show"
      >
        <div class="icon">
          <img src="@/assets/img/no-data-icon3.png" />
        </div>
        <div class="text">暂无面试报告</div>
      </div>
      <div
        @click="onLoadingMoreFn"
        class="data-text"
      >{{ loadingText }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      submitObj: {
        page_size: 10,
        page: 1
      },
      loadingText: '加载中...'
    }
  },
  created () {
    this.getInterViewRecordListsFn()
  },
  computed: {
    ...mapState([
      'interviewRecordLists'
    ])
  },
  methods: {
    ...mapActions([
      'getInterViewRecordLists'
    ]),
    // 面试记录列表
    async getInterViewRecordListsFn () {
      this.loadingText = '加载中...'
      const obj = await this.getInterViewRecordLists(this.submitObj)
      if (obj && obj.data.length === this.submitObj.page_size) {
        this.loadingText = '点击加载更多'
      } else {
        this.loadingText = '没有更多数据'
      }
    },
    // 点击加载更多
    onLoadingMoreFn () {
      this.submitObj.page += 1
      this.getInterViewRecordListsFn()
    },
    // 跳转详情页
    onSkipPageFn (item) {
      if (item.is_counting === 0) {
        this.$router.push({
          path: '/interview_report',
          query: { id: item.id }
        })
      } else if (item.is_counting === 1) {
        this.$toast.warn('评分中...可通过刷新等方式，时时捕捉最新状态。!')
      }
    },
    // 返回上一页
    onBackFn () {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .interview-wrapper
    width: 100%
    height: auto
    img
      display: block
      width: 100%
      height: 100%
    .return
      top: 8px
    .choose-box
      position: fixed
      top: 0
      left: 0
      z-index: 99
      width: 100%
      height: auto
      padding: 50px 0 24px
      box-sizing: border-box
      background: #fff
      .type
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: auto
        .item
          position: relative
          margin-right: 40px
          padding: 0 20px
          box-sizing: border-box
          .text
            padding-bottom: 10px
            box-sizing: border-box
            font-size: 32px
            font-weight: 600
            color: rgba(52, 39, 29, 0.5)
            text-align: center
          .line
            width: 100%
            height: 4px
            background: transparent
        .active
          .text
            color: rgba(52, 39, 29, 1)
          .line
            background: #ffdd38
        .item:last-child
          margin-right: 0
    .list-ul
      width: 100%
      height: auto
      padding: 150px 30px 0
      box-sizing: border-box
      .item
        width: 100%
        height: auto
        margin-bottom: 30px
        background: #fbfaf9
        border: 2px solid #eceae6
        border-radius: 26px
        .top-nav
          display: flex
          align-items: center
          justify-content: space-between
          width: 100%
          height: auto
          padding: 20px 30px
          box-sizing: border-box
          border-bottom: 2px solid #eceae6
          .title
            font-size: 28px
            font-weight: 500
            color: #34271d
          .icon
            width: 16px
            height: 28px
        .info-box
          display: flex
          align-items: center
          justify-content: space-between
          width: 100%
          height: auto
          padding: 30px
          box-sizing: border-box
          .text-box
            flex: 1
            .title
              margin-bottom: 20px
              font-size: 32px
              font-weight: 600
              color: #34271d
              display: -webkit-box
              word-break: break-all
              -webkit-box-orient: vertical
              -webkit-line-clamp: 2
              text-overflow: ellipsis
              overflow: hidden
            .date
              font-size: 28px
              font-weight: 400
              color: #7b756f
          .cover-box
            position: relative
            width: 224px
            height: 150px
            overflow: hidden
            border-radius: 12px
            .cover
              width: 100%
              height: auto
              min-height: 150px
              border-radius: 12px
            .mark
              position: absolute
              top: 0
              left: 0
              right: 0
              bottom: 0
              width: 100%
              height: 100%
              background:rgba(0,0,0,0.69)
              border-radius: 12px
            .data-box
              position: absolute
              top: 0
              display: flex
              align-items: center
              justify-content: center
              flex-direction: column
              width: 100%
              height: 100%
              .counting
                width: 100%
                height: auto
                .circle
                  width: 60px
                  height: 60px
                  margin: 0 auto
                  img
                    width: 100%
                    height: 100%
                .text
                  margin-top: 10px
                  font-size: 24px
                  font-weight: 600
                  text-align: center
                  color: #fff
              .score
                font-size: 48px
                font-weight: 600
                color: #ffdd38
                span
                  padding-left: 10px
                  box-sizing: border-box
                  font-size: 28px
      .item:last-child
        margin-bottom: 0
    .no-data-show
      padding-top: 360px
    .data-text
      line-height: 120px
      text-align: center
      font-size: 30px
      color: #777777
</style>
