<template>
  <div class="video-wrapper">
    <div class="content-center">
      <div
        v-if="callBackObj"
        class="border-box"
      >
        <div class="border">
          <img src="@/assets/img/interview-topic-border1.png" />
        </div>
        <div class="info-box">
          <div :class="!isStartAnswerState ? 'interview-date' : 'interview-date flex-change'">
            <div
              v-if="!isStartAnswerState"
              class="greetings-text"
            >你好，我是本次比赛的面试官。</div>
            <!--面试时长-->
            <div
              v-if="isStartAnswerState"
              class="greetings-text"
            >问题 {{ currentIndex + 1 }}/{{ callBackObj.question.length }}</div>
            <div
              v-if="isStartAnswerState"
              class="interview-duration"
            >时间：{{ h + ':' + m + ':' + s }}</div>
          </div>
          <div
            v-if="!isStartAnswerState"
            class="title"
          >
            <div class="text-box">
              <div class="text greetings-text">本次面试共{{ callBackObj.question.length }}题，每道答题时长建议在3分钟以内。</div>
            </div>
          </div>
          <div
            v-if="isStartAnswerState"
            class="title title-box"
          >
            <div class="text-box">
              <div class="text">{{ callBackObj.question[currentIndex].title }}</div>
            </div>
          </div>
        </div>
        <div class="border">
          <img src="@/assets/img/interview-topic-border2.png" />
        </div>
      </div>
      <div
        v-if="callBackObj"
        class="bottom-box"
      >
        <!--<div class="face-box">
          <div
            :class="{ 'item-active' : isBadLanguage }"
            class="item"
          >
            <div class="icon">
              <img src="@/assets/img/expression-icon1.png" />
            </div>
            <div class="text">请使用文明用语</div>
          </div>
        </div>-->
        <div class="data-box">
          <div class="mood">
            <div class="item">
              <div class="line-box">
                <div
                  class="line"
                  :style="{ height: nervousSpend + '%' }"
                ></div>
              </div>
              <div class="text">平缓</div>
            </div>
            <div class="item">
              <div class="line-box">
                <div
                  class="line"
                  :style="{ height: calmSpend + '%' }"
                ></div>
              </div>
              <div class="text">正常</div>
            </div>
            <div class="item">
              <div class="line-box">
                <div
                  class="line"
                  :style="{ height: excitedSpend + '%' }"
                ></div>
              </div>
              <div class="text">娴熟</div>
            </div>
          </div>
          <div class="speaking-rate">
            <div class="left-icon">
              <img v-if="timer" src="@/assets/img/interview-voice-icon1.gif" />
              <img v-else src="@/assets/img/interview-voice-icon.png" />
            </div>
            <div class="right">
              <div class="num-box">
                <div class="num">{{ timer && speakingRate ? speakingRate : 0 }}</div>
                <div class="text">
                  字/分
                  <div class="icon">
                    <img src="@/assets/img/voice-up-icon.png" />
                  </div>
                </div>
              </div>
              <div class="hint-text">语速</div>
            </div>
          </div>
        </div>
        <div
          v-if="!isStartAnswerState && !isAllowClick"
          class="start-btn"
        >
          <div class="text">开始答题</div>
        </div>
        <div
          v-if="!isStartAnswerState && isAllowClick"
          @click="onOpenAnswerFn"
          class="start-btn start-btn-active"
        >
          <div class="text">开始答题</div>
        </div>
        <div
          v-if="isStartAnswerState && !isAllowClick && sec === 0"
          class="start-btn"
        >
          <div class="text">载入中...</div>
        </div>
        <div
          v-if="isStartAnswerState && sec > 0 && sec < 5 && currentIndex + 1 !== callBackObj.question.length"
          class="start-btn"
        >
          <div class="text">作答完毕，下一题</div>
        </div>
        <div
          v-if="isStartAnswerState && sec >= 5 && currentIndex + 1 !== callBackObj.question.length"
          @click="onSaveFn(true)"
          class="start-btn start-btn-active"
        >
          <div class="text">作答完毕，下一题</div>
        </div>
        <div
          v-if="isStartAnswerState && sec > 0 && sec < 5 && currentIndex + 1 === callBackObj.question.length"
          class="start-btn"
        >
          <div class="text">提交面试结果</div>
        </div>
        <div
          v-if="isStartAnswerState && sec >= 5 && currentIndex + 1 === callBackObj.question.length"
          @click="onSaveFn(true)"
          class="start-btn end-btn-active"
        >
          <div class="text">提交面试结果</div>
        </div>
      </div>
      <div class="camera-mini">
        <video
          id="myVideo"
          class="myVideo"
          muted
          loop
          playsinline
          autoplay
        />
      </div>
    </div>
    <!--授权提示弹窗-->
    <nut-popup
      v-model:visible="authorizePopupState"
      :close-on-click-overlay="false"
    >
      <div class="popup-box-wrapper">
        <div class="content authorize-content">
          <div class="title">授权提示</div>
          <div class="hint">模拟面试为模拟真实的面试场景，达到面试的最佳效果，需要打开你的摄像头进行面试</div>
          <div class="strong">*为注重个人隐私，面试视频未经本人同意不会泄露。</div>
          <div
            @click="onCheckboxChangeFn"
            class="select-box"
          >
            <div class="icon">
              <img v-if="electState" src="@/assets/img/select-active-icon.png" />
              <img v-else src="@/assets/img/select-icon.png" />
            </div>
            <div class="text">同意开启摄像头</div>
          </div>
          <div class="btn-box">
            <div
              class="btn"
              @click="onCancelFn"
            >取消</div>
            <div
              class="btn confirm"
              @click="onConfirmFn"
            >开始面试</div>
          </div>
        </div>
      </div>
    </nut-popup>
    <!--开始答题倒计时-->
    <nut-popup
      v-model:visible="countdownPopupState"
      :close-on-click-overlay="false"
      style="width: 100%"
    >
      <div class="popup-box-wrapper">
        <div class="countdown-box">
          <div
            v-if="countdownNum === 3"
            class="countdown-text"
          >{{ countdownNum }}</div>
          <div
            v-if="countdownNum === 2"
            class="countdown-text"
          >{{ countdownNum }}</div>
          <div
            v-if="countdownNum === 1"
            class="countdown-text"
          >{{ countdownNum }}</div>
          <div
            v-if="countdownNum === 0"
            class="countdown-text"
          >{{ countdownNum }}</div>
        </div>
      </div>
    </nut-popup>
    <!--AI面试提示弹窗-->
    <nut-popup
      v-model:visible="hintPopupState"
      :close-on-click-overlay="false"
    >
      <div
        v-if="callBackObj"
        class="popup-box-wrapper"
      >
        <!--<div class="content">
          <div class="title">隐私保护</div>
          <div class="hint-box">
            <div>特别说明</div>
            <div>1.该视频仅限于AI面试，不做其他用途</div>
            <div>2.如发现他人非法下载或转载该视频，可依法提起申诉</div>
          </div>
          <div class="btn btn-active" @click="onHintPopupStateFn(false)">我知道了</div>
        </div>-->
        <div class="content">
          <div class="desc-box">
            <div class="title">恭喜你完成{{ callBackObj.title || '' }}AI面试!</div>
            <div class="word">预计<span>{{ '3' }}</span>分钟内可在【面试报告】内查看结果。</div>
          </div>
          <div
            class="btn btn-active"
            @click="onSkipMyTrainPageFn"
          >我知道了</div>
        </div>
      </div>
    </nut-popup>
    <!--语音播放-->
    <div class="audio-wrapper">
      <audio :src="myAudio.src" autoplay @ended="onStartInterviewFn" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { upFileYunUrl } from '@/api/config'

// const myAudio = new Audio()

// 音频转码worker
let recorderWorker = new Worker('/transformpcm.worker.js')
// 记录处理的缓存音频
let buffer = []
let AudioContext = window.AudioContext || window.webkitAudioContext

recorderWorker.onmessage = function (e) {
  buffer.push(...e.data.buffer)
}
class IatRecorder {
  constructor(config) {
    this.config = config
    // 以下信息在控制台-我的应用-实时语音转写 页面获取
    this.appId = '8cf420a4'
    this.apiKey = 'b59e63d209303d86cabda6279a1216b7'
  }
  start () {
    this.stop()
    if (navigator.getUserMedia && AudioContext) {
      if (!this.recorder) {
        const context = new AudioContext()
        this.context = context
        this.recorder = context.createScriptProcessor(0, 1, 1)

        const getMediaSuccess = (stream) => {
          this.audioMediaStream = this.context.createMediaStreamSource(stream)
          this.recorder.onaudioprocess = (e) => {
            this.sendData(e.inputBuffer.getChannelData(0))
          }
          this.connectWebsocket()
        }
        const getMediaFail = () => {
          this.context = null
          this.recorder = null
          this.audioMediaStream = null
          console.log('请求麦克风失败')
        }
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
          }).then((stream) => {
            getMediaSuccess(stream)
          }).catch((e) => {
            getMediaFail(e)
          })
        } else {
          navigator.getUserMedia({
            audio: true,
            video: false
          }, (stream) => {
            getMediaSuccess(stream)
          }, function (e) {
            getMediaFail(e)
          })
        }
      } else {
        this.connectWebsocket()
      }
    }
  }
  stop () {
    try {
      this.audioMediaStream.disconnect(this.recorder)
      this.recorder.disconnect()
    } catch (e) {
    }
  }
  sendData (buffer) {
    recorderWorker.postMessage({
      command: 'transform',
      buffer: buffer
    })
  }
  // 生成握手参数
  getHandShakeParams () {
    const appId = this.appId
    const secretKey = this.apiKey
    const ts = Math.floor(new Date().getTime() / 1000) // new Date().getTime()/1000 + ''
    const signa = hex_md5(appId + ts) // hex_md5(encodeURIComponent(appId + ts)) // EncryptUtil.HmacSHA1Encrypt(EncryptUtil.MD5(appId + ts), secretKey)
    const signatureSha = CryptoJSNew.HmacSHA1(signa, secretKey)
    let signature = CryptoJS.enc.Base64.stringify(signatureSha)
    signature = encodeURIComponent(signature)
    return '?appid=' + appId + '&ts=' + ts + '&signa=' + signature
  }
  connectWebsocket () {
    let url = 'wss://rtasr.xfyun.cn/v1/ws'
    const urlParam = this.getHandShakeParams()
    url = `${url}${urlParam}`
    if ('WebSocket' in window) {
      this.ws = new WebSocket(url)
    } else if ('MozWebSocket' in window) {
      this.ws = new MozWebSocket(url)
    } else {
      return false
    }
    this.ws.onopen = (e) => {
      this.audioMediaStream.connect(this.recorder)
      this.recorder.connect(this.context.destination)
      setTimeout(() => {
        this.wsOpened(e)
      }, 500)
      this.config.onStart && this.config.onStart(e)
    }
    this.ws.onmessage = (e) => {
      this.wsOnMessage(e)
    }
    this.ws.onerror = (e) => {
      this.stop()
      console.log("关闭连接ws.onerror")
      this.config.onError && this.config.onError(e)
    }
    this.ws.onclose = (e) => {
      this.stop()
      console.log("关闭连接ws.onclose")
      this.config.onClose && this.config.onClose(e)
    }
  }
  wsOpened () {
    if (this.ws.readyState !== 1) return
    const audioData = buffer.splice(0, 1280)
    this.ws.send(new Int8Array(audioData))
    this.handlerInterval = setInterval(() => {
      // websocket未连接
      if (this.ws.readyState !== 1) {
        clearInterval(this.handlerInterval)
        return
      }
      if (buffer.length === 0) {
        this.ws.send("{\"end\": true}")
        console.log("发送结束标识")
        clearInterval(this.handlerInterval)
        return false
      }
      const audioData = buffer.splice(0, 1280)
      if (audioData.length > 0) {
        this.ws.send(new Int8Array(audioData))
      }
    }, 40)
  }
  wsOnMessage (e) {
    let jsonData = JSON.parse(e.data)
    if (jsonData.action === "started") {
      // 握手成功
      console.log("握手成功")
    } else if (jsonData.action === "result") {
      // 转写结果
      if (this.config.onMessage && typeof this.config.onMessage === 'function') this.config.onMessage(jsonData.data)
    } else if (jsonData.action === "error") {
      // 连接发生错误
      console.log("出错了:", jsonData)
    }
  }
}
class IatTaste {
  constructor (funs) {
    const iatRecorder = new IatRecorder({
      onClose: () => {
        this.stop()
        this.reset()
      },
      onError: () => {
        this.stop()
        this.reset()
        alert('WebSocket连接失败')
      },
      onMessage: funs.onMessage,
      onStart: funs.onStart
    })
    this.iatRecorder = iatRecorder
  }
  start () {
    this.iatRecorder.start()
  }
  stop () {
    this.iatRecorder.stop()
  }
  reset () {
    buffer = []
  }
}

export default {
  data () {
    return {
      iatTaste: {}, // 讯飞语音转文字对象
      upyunData: null, // 又拍云签名信息
      sTopTime: null, // 开始答题倒计时定时器
      timer: null, // 计算面试时间定时器
      upFileYunUrl,

      callBackObj: null, // 获取面试题目内容
      currentIndex: 0, // 当前面试的题目位置
      isStartAnswerState: false, // 开始答题
      hintPopupState: false, // 提示弹窗状态
      countdownPopupState: false, // 倒计时弹窗状态
      authorizePopupState: false, // 授权提示弹窗状态
      countdownNum: 0, // 开始面试倒计时
      electState: false, // 是否开启摄像头状态
      isAllowClick: false, // 允许点击状态
      temporary_text: '', // 临时语音转文字存储
      sec: 0, // 每一题回答时长
      h: '00',
      m: '00',
      s: '00',
      speakingRate: 0, // 面试回答语速
      nervousSpend: 0, // 紧张度
      calmSpend: 0, // 平静度
      excitedSpend: 0, // 激动度
      isBadLanguage: false, // 是否有脏话
      videoEl: null, // 视频对象
      myAudio: {
        src: ''// 音频对象
      },
      mediaStream: null, // 媒体流
      mediaRecorder: null, // 媒体记录器
      recorderFile: null, // 文件记录
      stopRecordCallback: null, // 停止录制视频对象
      submitObj: { // 提交面试答案对象
        id: null,
        answer_video_url: [], // 每段视频的url
        answer_video_duration: [], // 每段视频的时长
        answer_text: [], // 每段语音识别文字
        time_consuming: 0 // 面试总时长
      }
    }
  },
  created () {
    const query = this.$route.query
    if (query.id) {
      this.submitObj.id = query.id
      this.getUpYunSignFn()
      this.getInterviewTrainingDetailFn()
    }
  },
  mounted () {
    this.videoEl = document.getElementById('myVideo')
    this.$nextTick(() => {
      this.iatTasteFn()
    })
  },
  methods: {
    ...mapActions([
      'getInterviewTrainingDetail',
      'getUpYunSign',
      'videoUpyun',
      'submitAnswer'
    ]),
    // 获取又拍云签名
    async getUpYunSignFn () {
      const res = await this.getUpYunSign()
      this.upyunData = res.data
    },
    // 面试训练内容
    async getInterviewTrainingDetailFn () {
      const obj = await this.getInterviewTrainingDetail({ id: this.submitObj.id })
      this.onAuthorizePopupStateFn()
      this.callBackObj = obj.data
    },
    // 创建语音转文字
    iatTasteFn () {
      const _this = this
      this.iatTaste = new IatTaste({
        onStart: () => {
          _this.temporary_text = ''
          console.log('onStart')
        },
        onMessage: (message) => {
          const data = JSON.parse(message)
          let rtasrResult = []
          rtasrResult[data.seg_id] = data
          rtasrResult.forEach(i => {
            let str = ''
            i.cn.st.rt.forEach(j => {
              j.ws.forEach(k => {
                k.cw.forEach(l => {
                  str += l.w
                })
              })
            })
            // console.log(str)
            _this.temporary_text += str
            console.log(_this.temporary_text)
          })
        }
      })
    },
    // 打开摄像头
    onOpenCameraFn () {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({video: true, audio: true}).then(this.success).catch(this.error)
      } else {
        alert('不支持访问用户媒体')
      }
    },
    success (stream) {
      let chunks = []
      this.mediaRecorder = new MediaRecorder(stream)
      this.videoEl.srcObject = stream
      this.mediaStream = stream
      this.mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data)
      }
      this.mediaRecorder.onstop = () => {
        this.recorderFile = new Blob(chunks, { type : this.mediaRecorder.mimeType })
        chunks = []
        if (null != this.stopRecordCallback) this.stopRecordCallback()
      }
      if (this.submitObj.time_consuming) this.musicPlayFn()
    },
    error (error) {
      alert(`请到应用管理将相机和录音授权允许!`)
      console.log(`访问用户媒体设备失败${error.name}, ${error.message}`)
    },
    /**
     * 关闭媒体流
     * @param stream {MediaStream} - 需要关闭的流
     */
    closeStream (stream) {
      if (typeof stream.stop === 'function') {
        stream.stop()
      } else {
        let trackList = [stream.getAudioTracks(), stream.getVideoTracks()]
        for (let i = 0; i < trackList.length; i++) {
          let tracks = trackList[i]
          if (tracks && tracks.length > 0) {
            for (let j = 0; j < tracks.length; j++) {
              let track = tracks[j]
              if (typeof track.stop === 'function') track.stop()
            }
          }
        }
      }
    },
    // 语音播报
    musicPlayFn () {
      const url = this.callBackObj.question[this.currentIndex].male_audio
      if (url) {
        this.myAudio.src = url
      } else {
        this.onStartInterviewFn()
      }
      // if (url) {
      //   myAudio.src = url
      //   myAudio.play()
      //   myAudio.addEventListener('ended', () => {
      //     this.onStartInterviewFn()
      //     this.isAllowClick = true
      //     this.timingStart()
      //   })
      // } else {
      //   this.onStartInterviewFn()
      //   this.isAllowClick = true
      //   this.timingStart()
      // }
      this.sec = 1
    },
    // 开启语音转文字
    onStartInterviewFn () {
      this.isAllowClick = true
      this.timingStart()
      if (navigator.getUserMedia && AudioContext && recorderWorker) {
        this.mediaRecorder.start() // 开始录像
        this.iatTaste.start() // 开始语音转文字
      }
    },
    // 停止录制视频
    stopRecord (callback) {
      this.stopRecordCallback = callback
      if (this.mediaRecorder) {
        // 终止录制器
        if (this.mediaRecorder.state !== 'inactive') this.mediaRecorder.stop()
        // 关闭媒体流
        this.closeStream(this.mediaStream)
      }
    },
    // 开始计时
    timingStart () {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.sec++
        this.submitObj.time_consuming++
        // 需要改变页面上时分秒的值
        this.s = this.showNum(this.submitObj.time_consuming % 60)
        this.m = this.showNum(parseInt(this.submitObj.time_consuming / 60) % 60)
        this.h = this.showNum(parseInt(this.submitObj.time_consuming / 60 / 60))
        this.speakingRateFn()
      }, 1000)
    },
    // 处理单位数字的函数
    showNum (num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    },
    // 存储到又拍云
    onSaveFn (state) {
      clearInterval(this.timer)
      this.timer = null
      this.isAllowClick = false
      // 结束语音转文字
      this.iatTaste.stop()
      buffer = []
      // 结束
      this.stopRecord(() => {
        if (state) this.generateFileFn()
      })
    },
    // 生成文件
    async generateFileFn () {
      const data = new FormData()
      const file = new File([this.recorderFile], 'msr-' + (new Date).toISOString().replace(/:|\./g, '-') + '.mp4', {
        type: 'video/mp4'
      })
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)

      const obj = await this.videoUpyun(data)
      this.submitObj.answer_video_url.push({
        id: this.callBackObj.question[this.currentIndex].id,
        urls: [this.upFileYunUrl + obj.url],
        ability_id: 0
      }) // 回答视频列表
      this.submitObj.answer_text.push({
        question_id: this.callBackObj.question[this.currentIndex].id,
        answer_text: this.temporary_text,
        duration: this.sec
      }) // 回答文字列表
      this.submitObj.answer_video_duration.push(this.sec) // 时间
      this.replyStatusFn()
    },
    // 回答题目状态
    replyStatusFn () {
      if (this.submitObj.answer_video_url.length === this.callBackObj.question.length) {
        this.sec = 0
        this.onSubmitAnswerFn()
      } else {
        this.temporary_text = ''
        this.currentIndex += 1
        this.sec = 0
        this.onOpenCameraFn()
      }
    },
    // 提交答案
    async onSubmitAnswerFn () {
      await this.submitAnswer(this.submitObj)
      this.onHintPopupStateFn(true)
    },
    // 打开授权提示弹窗
    onAuthorizePopupStateFn () {
      this.authorizePopupState = true
    },
    //  是否同意打开摄像头
    onCheckboxChangeFn () {
      if (this.electState) {
        this.electState = false
        this.isAllowClick = false
      } else {
        this.electState = true
        this.isAllowClick = true
      }
    },
    // 确定开启面试
    onConfirmFn () {
      if (this.electState) {
        if (this.isAllowClick) {
          this.authorizePopupState = false
          this.onOpenCameraFn()
        }
      } else {
        this.$toast.warn('同意开启摄像头后才能开始面试哦')
      }
    },
    // 开始答题
    onOpenAnswerFn () {
      this.countdownNum = 3
      this.onPopupStateFn(true)
    },
    // 打开提示弹窗
    onPopupStateFn (state) {
      if (state) {
        this.countdownPopupState = true
        this.onStartCountdownFn()
      } else {
        this.countdownPopupState = false
      }
    },
    // 开始答题倒计时
    onStartCountdownFn () {
      if (this.countdownNum > 0) {
        this.sTopTime = setTimeout(() => {
          this.countdownNum -= 1
          this.onStartCountdownFn()
        }, 1000)
      } else {
        clearInterval(this.sTopTime)
        this.onPopupStateFn(false)
        this.isStartAnswerState = true
        this.musicPlayFn()
      }
    },
    // 计算讲话语速
    speakingRateFn () {
      const maxSpeed = 999
      let strLong =  this.temporary_text.length
      let speakingRate = parseInt(strLong / (this.sec / 30))
      if (speakingRate > maxSpeed) speakingRate = Math.floor(Math.random() * (maxSpeed - 950)) + 950
      this.speakingRate = speakingRate // 语速
      if (speakingRate < 100 && this.nervousSpend < 100) {
        this.nervousSpend += 0.2 // 平缓
      } else if (speakingRate >= 100 && speakingRate < 200 && this.calmSpend < 100) {
        this.calmSpend += 0.2 // 正常
      } else if (speakingRate >= 200 && this.excitedSpend < 100) {
        this.excitedSpend += 0.2 // 娴熟
      }
    },
    // 取消开启摄像头
    onCancelFn () {
      this.authorizePopupState = false
      this.$router.back(-1)
    },
    // AI面试提示弹窗状态
    onHintPopupStateFn (state) {
      this.hintPopupState = state
    },
    // 跳转AI面试训练列表页
    onSkipMyTrainPageFn () {
      this.hintPopupState = false
      this.$router.push({
        path: '/my_interview'
      })
    }
  },
  // 销毁当前页面
  destroyed () {
    this.onSaveFn(false)
  }
}
</script>

<style lang="stylus" scoped>
  .video-wrapper
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    width: 100%
    height: auto
    background-color: #ccc
    img
      display: block
      width: 100%
      height: 100%
    .camera-mini
      position: fixed
      top: 0
      left: 0
      width: 100%
      height: 100%
      border-radius: 16px
      transform: rotateY(180deg)
      z-index: 1
      .myVideo
        width: 100%
        height: 100%
        object-fit: cover
    .content-center
      position: relative
      width: 100%
      height: 100%
      padding: 190px 20px 0
      box-sizing: border-box
      .border-box
        position: fixed
        left: 0
        top: 200px
        width: 100%
        padding: 0 20px
        z-index: 10
        box-sizing: border-box
        .border
          width: 100%
          height: 60px
        .info-box
          width: 100%
          height: auto
          padding: 40px 26px 40px 38px
          box-sizing: border-box
          /*border-left: 2px solid rgba(1, 83, 218, 0.3)
          border-right: 2px solid rgba(1, 83, 218, 0.3)*/
          .interview-date
            font-weight: bold
            color: #fff
            .greetings-text
              font-size: 30px
              line-height: 45px
              text-align: center
            .greetings-text-active
              opacity: 1
            .greetings-text-color
              color: #000
            &.flex-change
              display: flex
              justify-content: space-between
              font-size: 28px
              .greetings-text
                text-align: left
                font-size: 28px
          .title
            width: 100%
            height: auto
            margin-top: 20px
            .text-box
              .text
                width: 100%
                font-size: 28px
                line-height: 45px
                text-align: justify
                overflow-y: auto
                color: #fff
              .greetings-text
                text-align: center
              .greetings-text-color
                color: #000
          .title-box
            .text-box
              color: #fff
    .bottom-box
      position: fixed
      left: 0
      bottom: 80px
      width: 100%
      height: auto
      padding: 0 40px
      z-index: 10
      box-sizing: border-box
      .face-box
        display: inline-block
        margin-bottom: 16px
        .item
          display: flex
          align-items: center
          padding: 10px 20px 10px 10px
          box-sizing: border-box
          margin-bottom: 12px
          background: rgba(0, 0, 0, 0.2)
          border-radius: 50px
          transform: translateX(-200px)
          transition: all 0.3s
          .icon
            width: 32px
            height: 32px
          .text
            flex: 1
            padding-left: 10px
            box-sizing: border-box
            font-size: 24px
            color: #fff
        .item-active
          transform: translateX(0)
      .data-box
        display: flex
        .mood
          display: flex
          align-items: flex-end
          width: 236px
          height: 160px
          padding: 20px 28px 18px
          box-sizing: border-box
          margin-right: 16px
          background: rgba(0, 0, 0, 0.2)
          border-radius: 14px
          .item
            margin-right: 20px
            .line-box
              width: 16px
              height: 84px
              margin: 0 auto
              overflow: hidden
              display: flex
              flex-direction: column-reverse
              background: rgba(0, 0, 0, 0.1)
              border-radius: 50px
              .line
                width: 100%
                height: 0
                background: linear-gradient(0deg, #BBBCC6 0%, #FFFFFF 100%)
                border-radius: 50px
            .text
              width: 48px
              margin-top: 10px
              font-size: 24px
              text-align: center
              color: #fff
          .item:last-child
            margin-right: 0
        .speaking-rate
          display: flex
          align-items: center
          justify-content: space-between
          flex: 1
          width: auto
          height: 160px
          padding: 26px 20px
          box-sizing: border-box
          margin-bottom: 40px
          background: rgba(0, 0, 0, 0.2)
          border-radius: 14px
          .left-icon
            width: 156px
            height: 80px
          .right
            padding-left: 20px
            box-sizing: border-box
            font-size: 22px
            color: #fff
            .num-box
              display: flex
              align-items: center
              .num
                font-size: 93px
                font-weight: 600
                line-height: 90px
              .text
                width: 80px
                padding-left: 15px
                box-sizing: border-box
                .icon
                  margin-top: 10px
                  width: 40px
                  height: 40px
            .hint-text
              padding-left: 5px
              width: 60px
              height: 38px
              box-sizing: border-box
      .start-btn
        width: 100%
        height: auto
        line-height: 108px
        text-align: center
        font-size: 36px
        border-radius: 10px
        color: #fff
        background-color: #999
      .start-btn-active
        background-color: #FF7133
      .end-btn-active
        background-color: #09BB07
    .countdown-box
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      .countdown-text
        font-size: 240px
        font-weight: 600
        background: linear-gradient(180deg, #FFC801 0%, #FFFCC7 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
    .popup-box-wrapper
      .content
        .hint
          margin-top: 32px
          font-size: 28px
          line-height: 46px
          color: #7B756F
        .hint-box
          margin: 30px 0 60px
          padding: 0 46px 0 70px
          box-sizing: border-box
          line-height: 54px
          font-size: 34px
          color: #7B756F
        .desc-box
          padding-bottom: 60px
          box-sizing: border-box
          .word
            margin-top: 32px
            font-size: 30px
            text-align: center
            color: #666
            line-height: 46px
            span
              color: #fd5b77
        .strong
          margin-bottom: 60px
          font-size: 28px
          line-height: 46px
          color: #ff7133
          font-weight: 600
        .select-box
          display: flex
          align-items: center
          justify-content: center
          width: 100%
          height: auto
          margin-bottom: 40px
          .icon
            width: 32px
            height: 32px
          .text
            padding-left: 14px
            box-sizing: border-box
            font-size: 28px
            color: #34271D
        .btn-box
          display: flex
          justify-content: space-between
          width: 100%
          height: auto
        .btn
          width: 272px
          height: auto
          line-height: 100px
          text-align: center
          font-size: 32px
          font-weight: 600
          color: #7B756F
          background: #F4F3EF
          border-radius: 20px
        .confirm
          color: #34271D
          background: #FFDD38
        .btn-active
          width: 500px
          margin: 0 auto
          color: #34271D
          background: #FFDD38
      .authorize-content
        padding: 64px 50px 40px
        box-sizing: border-box
    .audio-wrapper
      position: absolute
      left: 99999px
      top: 99999px
      z-index: -999
      opacity: 0
</style>
