<template>
  <div v-if="callBackObj" class="report-wrapper">
    <div class="content-center">
      <div class="return" @click="onBackFn">
        <img src="@/assets/img/return-icon1.png" />
      </div>
      <div class="fixed-bg"></div>
      <div class="top-bg"></div>
      <div class="choose-box">
        <div class="type">
          <div
            :class="currentIndex === 0 ? 'item active' : 'item'"
            @click="onSwitchItemFn(0)"
          >
            <div class="text">面试数据</div>
            <div class="line"></div>
          </div>
          <div
            :class="currentIndex === 1 ? 'item active' : 'item'"
            @click="onSwitchItemFn(1)"
          >
            <div class="text">面试回顾</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <analyze-report
        v-if="currentIndex === 0"
        :oItem="callBackObj"
      />
      <video-review
        v-if="currentIndex === 1"
        :answerList="callBackObj.answer_score_list"
        :videoList="callBackObj.answer_video_url"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AnalyzeReport from '@/components/aiAnalyzeReportTemplate'
import VideoReview from '@/components/aiVideoReviewTemplate'

export default {
  data () {
    return {
      callBackObj: null,
      currentIndex: 0
    }
  },
  created () {
    const query = this.$route.query
    if (query) this.getInterviewTrainDetailFn(query.id)
  },
  methods: {
    ...mapActions([
      'getInterviewTrainDetail'
    ]),
    // 面试报告详情
    async getInterviewTrainDetailFn (id) {
      this.callBackObj = await this.getInterviewTrainDetail({ answer_id: id })
    },
    // 切换菜单
    onSwitchItemFn (index) {
      this.currentIndex = index
    },
    // 返回上一页
    onBackFn () {
      this.$router.back(-1)
    }
  },
  components: {
    AnalyzeReport,
    VideoReview
  }
}
</script>

<style lang="stylus" scoped>
  .report-wrapper
    width: 100%
    height: auto
    img
      display: block
      width: 100%
      height: 100%
    .return
      top: 8px
    .fixed-bg
      position: fixed
      top: 0
      left: 0
      z-index: -1
      width: 100%
      height: 100%
      background: #F5F8FA
    .top-bg
      position: fixed
      top: 0
      left: 0
      right: 0
      z-index:  -1
      width: 100%
      height: 500px
      background: linear-gradient(180deg, #FFFFFF 0%, #F5F8FA 100%)
    .choose-box
      width: 100%
      height: auto
      padding-top: 36px
      box-sizing: border-box
      .type
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: auto
        .item
          position: relative
          z-index: 100
          padding: 10px 40px
          box-sizing: border-box
          .text
            z-index: 100
            padding: 0 6px 16px
            box-sizing: border-box
            font-size: 32px
            font-weight: 600
            color: rgba(52, 39, 29, 0.5)
            text-align: center
          .line
            width: 45%
            height: 4px
            margin: 0 auto
            background: transparent
            border-radius: 2px
        .active
          .text
            z-index: 100
            color: #34271D
          .line
            background: #FFDD38
</style>
