import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import NutUI  from '@nutui/nutui'
import ElementUI from 'element-ui'
import '@nutui/nutui/dist/nutui.css'
import '@/assets/css/style.css'
import 'element-ui/lib/theme-chalk/index.css'

require('./utils/hmac-sha256')
require('./utils/HmacSHA1')
require('./utils/enc-base64-min')
require('./utils/md5')

Vue.config.productionTip = false
Vue.prototype.$store = store
NutUI.install(Vue)
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
