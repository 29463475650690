import axios from 'axios'
import {
  apiUrl,
  upyunSignApiUrl,
  upyunApiUrl
} from '@/api/config'

const obj = localStorage.getItem('parameter')

// 获取又拍云签名
export const httpRequestUpyunSign = axios.create({
  baseURL: upyunSignApiUrl
})

// 又拍云上传文件
export const httpRequestUpyun = axios.create({
  baseURL: upyunApiUrl
})

// 提供给外部使用的AI面试请求
export const httpRequest = axios.create({
  baseURL: apiUrl,
  headers: JSON.parse(obj)
})
// 又拍云添加请求拦截器
httpRequestUpyunSign.interceptors.request.use((params) => {
  return params
}, (error) => {
  return Promise.reject(error)
})
// 又拍云添加响应拦截器
httpRequestUpyunSign.interceptors.response.use((res) => {
  return res.data
}, (error) => {
  return Promise.reject(error)
})
// 又拍云添加请求拦截器
httpRequestUpyun.interceptors.request.use((params) => {
  return params
}, (error) => {
  return Promise.reject(error)
})
// 又拍云添加响应拦截器
httpRequestUpyun.interceptors.response.use((res) => {
  return res.data
}, (error) => {
  return Promise.reject(error)
})
// AI面试添加请求拦截器
httpRequest.interceptors.request.use((params) => {
  return params
}, (error) => {
  return Promise.reject(error)
})
// AI面试添加响应拦截器
httpRequest.interceptors.response.use((res) => {
  return res.data
}, (error) => {
  return Promise.reject(error)
})
