<template>
  <div class="video-play-wrapper">
    <div class="top-box">
      <div class="video-box">
        <div
          v-if="videoList[currentIndex].urls[0]"
          class="video-content"
        >
          <video
            :src="videoList[currentIndex].urls[0]"
            :custom-cache="false"
            @click="onPlayVideoFn"
            class="video"
            ref="video"
          >
          </video>
          <div
            v-if="!playState"
            @click="onPlayVideoFn"
            class="mark-content"
          >
            <div class="play-icon">
              <img src="@/assets/img/play-icon1.png" />
            </div>
          </div>
        </div>
        <div
          v-else
          class="video-content-poster"
        >视频加载失败!</div>
      </div>
    </div>
    <div class="topic-box">
      <el-carousel
        v-if="answerList.length"
        ref="carousel"
        :initial-index="currentIndex"
        arrow="always"
        :autoplay="false"
        :loop="false"
        indicator-position="none"
        @change="onChangeFn"
      >
        <el-carousel-item
          v-for="(item, index) in answerList"
          :key="index"
        >
          <div class="word-box">
            <div class="circle"></div>
            <div class="text">({{ index + 1 }}/{{ answerList.length }}){{ item.title }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div
      v-if="answerList.length"
      class="dot-box"
    >
      <div
        v-for="(item, index) in answerList"
        :key="index"
        :class="currentIndex === index ? 'dot active' : 'dot'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      playState: false,
      currentIndex: 0
    }
  },
  created () {
  },
  methods: {
    // 设置滑动切换轮播图
    onChangeFn (index) {
      this.currentIndex = index
      this.playState = false
      this.onPlayVideoFn()
    },
    // 播放视频
    onPlayVideoFn () {
      const _this = this
      if (this.videoList[this.currentIndex].urls[0]) {
        if (this.playState) {
          this.$nextTick(() => {
            this.$refs.video.pause()
          })
          this.playState = false
        } else {
          this.$nextTick(() => {
            this.$refs.video.play()
          })
          this.playState = true
        }
        this.$refs.video.addEventListener('ended', function () { // 结束
          _this.playState = false
        }, false)
      }
    }
  },
  props: {
    answerList: {
      type: Array,
      default: () => ([])
    },
    videoList: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="stylus" scoped>
  .video-play-wrapper
    width: 100%
    height: auto
    padding: 60px 40px 134px
    box-sizing: border-box
    img
      display: block
      width: 100%
      height: 100%
    .top-box
      width: 100%
      height: auto
      padding: 0 54px 80px
      box-sizing: border-box
      .video-box
        width: 100%
        height: auto
        .video-content
          position: relative
          width: 100%
          height: 784px
          border-radius: 26px
          .video
            display: block
            width: 100%
            height: 784px
            margin: 0 auto
            border-radius: 26px
            object-fit: cover
          .mark-content
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            background: rgba(0,0,0,0.5)
            border-radius: 26px
            .play-icon
              position: absolute
              left: 50%
              top: 50%
              z-index: 10
              width: 80px
              height: 104px
              margin-left: -40px
              margin-top: -65px
        .video-content-poster
          position: relative
          width: 100%
          height: 840px
          border-radius: 26px
          font-size: 50px
          font-weight: 500
          text-align: center
          line-height: 840px
          overflow: hidden
          color: #fff
          background-color: #000
    .topic-box
      width: 100%
      height: 340px
      background: #fff
      border: 2px solid #F2F1F0
      border-radius: 26px
      .word-box
        position: relative
        width: 100%
        height: 100%
        padding: 30px
        box-sizing: border-box
        .circle
          position: absolute
          top: 20px
          width: 12px
          height: 12px
          background: #ffdd38
          border-radius: 50%
        .text
          width: 100%
          height: 270px
          overflow: hidden
          overflow-y: auto
          padding-left: 30px
          box-sizing: border-box
          font-size: 30px
          line-height: 46px
          color: #34271d
          white-space: normal !important
    .dot-box
      width: 100%
      height: auto
      margin-top: 30px
      display: flex
      justify-content: center
      .dot
        width: 8px
        height: 8px
        margin: 0 8px
        background: #B3B0AD
        border-radius: 50%
        &.active
          width: 20px
          background: #FFDD38
          border-radius: 4px
    ::-webkit-scrollbar
      width: 0
      height: 0
      color: transparent
</style>
