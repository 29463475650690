<template>
  <div id="app">
    <router-view v-if="isH5Weixin && pageStatus"/>
    <div
      v-if="!isH5Weixin"
      class="weui_msg"
    >
      <div class="weui_icon_area">
        <i class="weui_icon_info weui_icon_msg"></i>
      </div>
      <div class="weui_text_area">
        <h4 class="weui_msg_title">请在微信客户端打开链接</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      pageStatus: false,
      isH5Weixin: true
    }
  },
  created () {
    // localStorage.removeItem('parameter')
    // const ua = navigator.userAgent.toLowerCase()
    // this.isH5Weixin = ua.indexOf('micromessenger') !== -1
    if (!this.isH5Weixin) return false
    const parameter = JSON.parse(localStorage.getItem('parameter'))
    const query = this.$route.query
    let authorization = null
    if (query.authorization) authorization = query.authorization
    if (parameter && authorization) { // 有本地缓存也有参数
      if (authorization && parameter.Authorization === authorization) { // 有参数并且缓存用户和参数用户一致
        this.pageStatus = true
      } else if (authorization && parameter.Authorization !== authorization) { // 有参数并且缓存用户和参数用户不一致
        this.getAppKeyFn(authorization)
      } else if (!authorization) { // 有参数没有用户标识
        this.$toast.warn('参数错误!')
      }
    } else if (!parameter && authorization) { // 没有缓存但是有参数
      if (authorization) { // 有用户标识
        this.getAppKeyFn(authorization)
      } else if (!authorization) { // 没有用户标识
        this.$toast.warn('参数错误!')
      }
    } else if (parameter && !authorization) { // 有缓存但是没参数
      this.pageStatus = true
    } else if (!parameter && !authorization) { // 没有缓存也没有参数
      this.$toast.warn('参数错误!')
    }
  },
  methods: {
    ...mapActions([
      'getAppKey'
    ]),
    // 获取APPKey
    async getAppKeyFn (authorization) {
      const toast = this.$toast.loading('加载中...')
      const obj = await this.getAppKey()
      toast.hide()
      const data = {
        id: obj.id,
        secret: obj.secret,
        Authorization: authorization
      }
      localStorage.setItem('parameter', JSON.stringify(data))
      location.reload()
    }
  }
}
</script>

<style>
</style>
