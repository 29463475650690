import Vue from 'vue'
import Router from 'vue-router'
import AiInterviewIndex from '@/views/aiInterview'
import AiInterviewLists from '@/views/aiInterview/aiInterviewLists'
import InterviewDetail from '@/views/aiInterview/interviewDetail'
import InterviewVideo from '@/views/aiInterview/interviewVideo'
import MyInterview from '@/views/aiInterview/myInterview'
import InterviewReport from '@/views/aiInterview/interviewReport'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '',
      name: 'aiInterviewIndex',
      title: 'AI面试',
      component: AiInterviewIndex,
      children: [
        {
          path: '/',
          name: 'aiInterviewIndex',
          title: 'AI题目列表',
          component: AiInterviewLists
        },
        {
          path: '/interview_detail',
          name: 'interviewDetail',
          title: 'AI面试详情',
          component: InterviewDetail
        },
        {
          path: '/interview_video',
          name: 'interviewVideo',
          title: 'AI面试详情',
          component: InterviewVideo
        },
        {
          path: '/my_interview',
          name: 'myInterview',
          title: '我的面试训练',
          component: MyInterview
        },
        {
          path: '/interview_report',
          name: 'interviewReport',
          title: '我的面试报告',
          component: InterviewReport
        }
      ]
    }
  ]
})
