<template>
  <div class="aiInterview-index-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="stylus" scoped>
  .aiInterview-index-wrapper
    width: 100%
    height: 100%
</style>
