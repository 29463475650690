import * as types from './mutation-types'

const mutations = {
  [types.SET_EQUIPMENT_TYPE] (state, data) {
    state.equipmentType = data
  },
  [types.SET_COMPANY_INFO] (state, data) {
    state.companyInfo = data
  },
  [types.SET_PHOTO_LISTS] (state, data) {
    state.photoLists = data
  },
  [types.SET_OITEM_PHOTO_INFO] (state, data) {
    state.oItemPhotoInfo = data
  },
  [types.SET_PHOTO_TYPE] (state, data) {
    state.photoType = data
  },
  [types.SET_PHOTO_CLASS_INDEX] (state, data) {
    state.photoClassIndex = data
  },
  [types.SET_PHOTO_CURRENT_INDEX] (state, data) {
    state.photoCurrentIndex = data
  },
  [types.SET_INTERVIEW_TRAIN_LISTS] (state, data) {
    state.interviewTrainLists = data
  },
  [types.SET_INTERVIEW_RECORD_LISTS] (state, data) {
    state.interviewRecordLists = data
  }
}

export default mutations
