<template>
  <div
    class="content-wrapper"
    @click="onShowHintFn(false)"
  >
    <div class="score-box">
      <div
        v-if="oItem.info"
        class="name"
      >
        {{ oItem.info.title }}
      </div>
      <div class="score">{{ oItem.total_score || '0' }}<span>分</span></div>
      <div class="hint">本次面试击败同职位<span>{{ oItem.competitiveness_abroad }}%</span>面试者</div>
    </div>
    <div class="data-box">
      <div class="list-box">
        <div class="item">
          <div class="text">开始时间</div>
          <div class="title">{{ beginTime }}</div>
        </div>
        <div class="item">
          <div class="text">面试题数</div>
          <div class="title">共{{ oItem.answer_score_list.length }}题</div>
        </div>
        <div class="item">
          <div class="text">面试时长</div>
          <div class="title">{{ allMin }}分{{ allSecond }}秒</div>
        </div>
      </div>
      <div class="line-box">
        <div class="line"></div>
        <div class="circle"></div>
        <div class="line"></div>
      </div>
      <div class="list-box last">
        <div class="item">
          <div class="text">单题用时最短</div>
          <div class="title">{{ minMin }}分{{ minSecond }}秒</div>
        </div>
        <div class="item">
          <div class="text">单题用时最长</div>
          <div class="title">{{ maxMin }}分{{ maxSecond }}秒</div>
        </div>
      </div>
    </div>
    <div class="data-box arcbar-data">
      <div class="title-box">
        <div class="title">综合表现</div>
        <div
          v-if="oItem.explanation_tip.length"
          class="icon"
          @click.stop="onShowHintFn(1, true)"
        >
          <img src="@/assets/img/hint-icon4.png" />
        </div>
        <div
          v-if="hintIndex === 1 && hideState && oItem.explanation_tip.length"
          class="hint-box"
        >
          <span>{{ oItem.explanation_tip[0] }}</span>
        </div>
      </div>
      <div
        v-if="oItem.score_compose"
        class="schedule-box"
      >
        <div
          v-show="oItem.score_compose && oItem.score_compose.representation"
          class="item"
        >
          <div class="charts-box">
            <div class="arcbar" id="arcbar1"></div>
            <div class="dot">{{ oItem.score_compose.representation.title }}</div>
          </div>
          <div class="name">{{ oItem.score_compose.representation.title_m }}</div>
          <div class="score"><span>{{ oItem.score_compose.representation.score }}</span>分</div>
        </div>
        <div
          v-show="oItem.score_compose && oItem.score_compose.profession"
          class="item"
        >
          <div class="charts-box">
            <div class="arcbar" id="arcbar2"></div>
            <div class="dot">{{ oItem.score_compose.profession.title }}</div>
          </div>
          <div class="name">{{ oItem.score_compose.profession.title_m }}</div>
          <div class="score"><span>{{ oItem.score_compose.profession.score }}</span>分</div>
        </div>
        <div
          v-show="oItem.score_compose && oItem.score_compose.manner"
          class="item"
        >
          <div class="charts-box">
            <div class="arcbar" id="arcbar3"></div>
            <div class="dot">{{ oItem.score_compose.manner.title }}</div>
          </div>
          <div class="name">{{ oItem.score_compose.manner.title_m }}</div>
          <div class="score"><span>{{ oItem.score_compose.manner.score }}</span>分</div>
        </div>
      </div>
      <div
        v-if="!oItem.score_compose"
        class="no-data-show"
      >
        <div class="icon">
          <img src="@/assets/img/no-data-icon1.png" />
        </div>
        <div class="text">暂无数据</div>
      </div>
    </div>
    <div class="data-box">
      <div class="title-box title-padding">
        <div class="title">能力雷达</div>
        <div
          v-if="oItem.explanation_tip.length"
          class="icon"
          @click.stop="onShowHintFn(2, true)"
        >
          <img src="@/assets/img/hint-icon4.png" />
        </div>
        <div
          v-if="hintIndex === 2 && hideState && oItem.explanation_tip.length"
          class="hint-box"
        >
          <span>{{ oItem.explanation_tip[1] }}</span>
        </div>
      </div>
      <div
        v-show="oItem.radar.length"
        class="charts-box"
        id="radar"
      ></div>
      <div class="expression-box">
        <div class="title">基于本次面试表现：</div>
        <div class="ul-box">
          <div class="item">
            <div class="title-label">表现较好的能力</div>
            <div
              v-if="oItem.radar_good.length"
              class="text-box"
            >
              <div
                v-for="(item, index) in oItem.radar_good"
                class="text"
                :key="index"
              >
                <div class="circle"></div>
                <span>{{ item }}</span>
              </div>
            </div>
            <div v-if="!oItem.radar_good.length" class="no-data">无</div>
          </div>
          <div
            v-if="oItem.radar_bad.length"
            class="item last"
          >
            <div class="title-label blue-label">有待提高的能力</div>
            <div class="text-box">
              <div
                v-for="(item, index) in oItem.radar_bad"
                class="text"
                :key="index"
              >
                <div class="circle"></div>
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box">
      <div class="title-box title-padding">
        <div class="title">答题表现</div>
        <div
          v-if="oItem.explanation_tip.length"
          class="icon"
          @click.stop="onShowHintFn(3, true)"
        >
          <img src="@/assets/img/hint-icon4.png" />
        </div>
        <div
          v-if="hintIndex === 3 && hideState && oItem.explanation_tip.length"
          class="hint-box"
        >
          <span>{{ oItem.explanation_tip[2] }}</span>
        </div>
      </div>
      <div
        v-show="oItem.pepresentation"
        class="charts-box"
        id="area1"
      >
        <div class="line-ul">
          <div class="line"></div>
        </div>
        <div class="text">正常</div>
      </div>
      <div
        v-if="!oItem.pepresentation"
        class="no-data-show"
      >
        <div class="icon">
          <img src="@/assets/img/no-data-icon1.png" />
        </div>
        <div class="text">暂无数据</div>
      </div>
    </div>
    <div class="data-box">
      <div class="title-box data-title">
        <div class="title">答题解析</div>
      </div>
      <div
        v-if="oItem.bad_answer.length"
        class="topic-box"
      >
        <div class="title-label blue-label">有待提高</div>
        <div class="scroll-box">
          <div class="scroll-view">
            <div
              class="list-ul"
            >
              <div
                v-for="(item, index) in oItem.bad_answer"
                class="item"
                :key="index"
              >
                <div class="title">{{ item.index }}：{{ item.title }}</div>
                <div v-if="item.temp.length" class="text-box">
                  <div
                    v-for="(obj, i) in item.temp"
                    class="text"
                    :key="i"
                  >
                    {{ obj }}
                  </div>
                  <div class="more" @click.stop="onTopicPopupStateFn(item, true)">更多</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="oItem.good_answer.length"
        class="topic-box"
      >
        <div class="title-label">表现较好</div>
        <div class="scroll-box">
          <div class="scroll-view">
            <div
              class="list-ul"
            >
              <div
                v-for="(item, index) in oItem.good_answer"
                class="item"
                :key="index"
              >
                <div class="title">{{ item.index }}：{{ item.title }}</div>
                <div v-if="item.temp.length" class="text-box">
                  <div
                    v-for="(obj, i) in item.temp"
                    class="text"
                    :key="i"
                  >
                    {{ obj }}
                  </div>
                  <div class="more" @click.stop="onTopicPopupStateFn(item, true)">更多</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!oItem.good_answer.length"
        class="no-data-show"
      >
        <div class="icon">
          <img src="@/assets/img/no-data-icon1.png" />
        </div>
        <div class="text">暂无数据</div>
      </div>
    </div>
    <div class="data-box">
      <div class="title-box last-title">
        <div class="title">该职位最近练习表现</div>
        <div class="date-box">
          <div
            :class="dateIndex === 0 ? 'btn active' : 'btn'"
            @click="onSelectDateFn(0, false)"
          >近7日</div>
          <div
            :class="dateIndex === 1 ? 'btn active' : 'btn'"
            @click="onSelectDateFn(1, false)"
          >近30日</div>
        </div>
      </div>
      <div
        v-if="oItem.laslately"
        class="analysis-box"
      >
        <div class="text-box">
          <div class="text">{{ oItem.laslately.create_time }}<span>{{ oItem.laslately.total_score }}分</span></div>
          <div class="text last-text">
            {{ oItem.laslately.improve > 0 ? '同比上次提高' : '同比上次降低' }}<span class="percent">{{ Math.abs(oItem.laslately.improve) }}%</span>
          </div>
        </div>
      </div>
      <div v-show="dateIndex === 0" class="charts-box" id="area2" style="width: 100%"></div>
      <div v-show="dateIndex === 1" class="charts-box" id="area3" style="width: 100%"></div>
    </div>
    <div class="data-box">
      <div class="title-box data-title">
        <div class="title">同职位竞争力</div>
      </div>
      <div class="show-box">
        <div class="text-box">
          <div
            v-if="oItem.info"
            class="text"
          >同练习对比：{{ oItem.info.title }}</div>
          <div class="text">面试分数：{{ oItem.total_score || '0' }}分</div>
        </div>
        <div
          v-if="oItem.competitiveness"
          class="table"
        >
          <div class="line first"></div>
          <div class="line"></div>
          <div class="ul-box">
            <div
              v-for="(item, index) in oItem.competitiveness.y"
              class="item-box"
              :key="index"
            >
              <div
                :style="{ height: item.num > 0 ? item.num * 2 + 'px' : 6 + 'px' }"
                :class="{ 'active' : item.is_current === 1 }"
                class="item"
              ></div>
            </div>
          </div>
        </div>
        <div
          v-if="oItem.competitiveness"
          class="day-info"
        >
          <div class="day-box">
            <div
              v-for="(item, index) in oItem.competitiveness.x"
              class="num"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <div class="text">(分)</div>
        </div>
        <div class="hint">本次练习击败同职位的 <span>{{ oItem.competitiveness_abroad }}%</span>练习者</div>
      </div>
    </div>
    <!--题目介绍提示弹窗-->
    <nut-popup
      v-model:visible="topicPopupState"
      style="width: 100%"
    >
      <div
        class="popup-box-wrapper"
        @click="onTopicPopupStateFn(null, false)"
      >
        <div class="content">
          <div
            v-if="topicObj"
            class="info-box"
            @click.stop
          >
            <div class="name">{{ topicObj.index }}：{{ topicObj.title }}</div>
            <div
              v-if="topicObj.temp.length"
              class="text-box"
            >
              <div
                v-for="(text, i) in topicObj.temp"
                :key="i"
                class="text"
              >{{ text }}</div>
            </div>
          </div>
        </div>
      </div>
    </nut-popup>
  </div>
</template>

<script>
// 引入echart
let echarts = require('echarts')
// 引入饼状图
// require("echarts/lib/chart/pie")
// 引入title等等
// require("echarts/lib/component/tooltip")
// require("echarts/lib/component/title")
// require("echarts/lib/component/legend")
// require("echarts/lib/component/legendScroll")
// 引入svg
require('zrender/lib/svg/svg')

export default {
  data () {
    return {
      type: 0,
      obj: null,
      showIndex: null,
      beginTime: null, // 开始面试时间
      allMin: 0, // 时长时间分
      allSecond: 0, // 时长时间秒
      maxMin: 0, // 单题最长时间分
      maxSecond: 0, // 单题最长时间秒
      minMin: 0, // 单题最短时间分
      minSecond: 0, // 单题最短时间秒
      hideState: false,
      hintIndex: 0,
      dateIndex: 0,
      radarName: [],
      radarScore: [],
      topicPopupState: false, // 题目弹窗状态
      topicObj: null // 题目参数
    }
  },
  mounted () {
    if (this.oItem) {
      this.getArcbarFn()
      this.getRadarFn()
      this.getAreaFn(true)
    }
  },
  methods: {
    // 获取环形图
    getArcbarFn () {
      let arcbarChart1 = echarts.init(document.getElementById('arcbar1'), null, { renderer: 'svg' })
      let arcbarChart2 = echarts.init(document.getElementById('arcbar2'), null, { renderer: 'svg' })
      let arcbarChart3 = echarts.init(document.getElementById('arcbar3'), null, { renderer: 'svg' })
      // 指定图表的配置项和数据
      let option1 = {
        series: [
          {
            type: 'pie',
            radius: ['63%', '70%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            silent: false,
            label: { show: false },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: { show: false },
            color: ['#F4F3EF', '#FFDD38'],
            data: [
              { value: 100 - this.oItem.score_compose.representation.score },
              { value: this.oItem.score_compose.representation.score }
            ]
          }
        ]
      }
      let option2 = {
        series: [
          {
            type: 'pie',
            radius: ['63%', '70%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            silent: false,
            label: { show: false },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: { show: false },
            color: ['#F4F3EF', '#FF7133'],
            data: [
              { value: 100 - this.oItem.score_compose.profession.score },
              { value: this.oItem.score_compose.profession.score }
            ]
          }
        ]
      }
      let option3 = {
        series: [
          {
            type: 'pie',
            radius: ['63%', '70%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            silent: false,
            label: { show: false },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: { show: false },
            color: ['#F4F3EF', '#41C544'],
            data: [
              { value: 100 - this.oItem.score_compose.manner.score },
              { value: this.oItem.score_compose.manner.score }
            ]
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      arcbarChart1.setOption(option1)
      arcbarChart2.setOption(option2)
      arcbarChart3.setOption(option3)
    },
    // 获取雷达图
    getRadarFn () {
      let radarChart = echarts.init(document.getElementById('radar'), null, { renderer: 'svg' })
      let option = {
        radar: [
          {
            indicator: this.radarName,
            radius: 68,
            splitNumber: 3,
            shape: 'circle',
            center: ['50%', '48%'],
            startAngle: 120,
            axisName: {
              formatter: '{value}',
              color: '#34271d'
            },
            splitArea: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#dedcda'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#dedcda'
              }
            }
          }
        ],
        series: [
          {
            type: 'radar',
            itemStyle: {
              color: 'transparent',
              borderColor: 'transparent'
            },
            areaStyle: {
              color: '#ffdd38'
            },
            data: [
              {
                value: this.radarScore
              }
            ]
          }
        ]
      }
      radarChart.setOption(option)
    },
    // 切换该职位最近练习表现日期
    onSelectDateFn (index, state) {
      this.dateIndex = index
      setTimeout(() => {
        this.getAreaFn(state)
      }, 1000)
    },
    // 获取面积图
    getAreaFn (state) {
      let areaChart1 = echarts.init(document.getElementById('area1'), null, { renderer: 'svg' })
      let areaChart2 = echarts.init(document.getElementById('area2'), null, { renderer: 'svg' })
      let areaChart3 = this.dateIndex === 1 ? echarts.init(document.getElementById('area3'), null, { renderer: 'svg' }) : null
      let option1 = {
        grid: {
          x: 50,
          y: 10,
          x2: 30,
          y2: 40
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#34271d'
            }
          },
          data: this.oItem.pepresentation.x
        },
        yAxis: {
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          axisLabel: {
            textStyle: {
              color: 'transparent'
            }
          },
          axisTick: { show: false },
          splitLine: { show: false },
          type: 'value',
          min: 0,
          max: 100
        },
        series: [
          {
            data: this.oItem.pepresentation.y,
            type: 'line',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#FFDD38' // 100% 处的颜色
                }, {
                  offset: 1, color: '#FFFFFF' //  0% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            color: '#FFDD38', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
      }
      let option2 = {
        grid: {
          x: 38,
          y: 10,
          x2: 25,
          y2: 40
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#34271d'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          data: this.oItem.history.week.x
        },
        yAxis: {
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#34271d'
            }
          },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          type: 'value',
          min: 0,
          max: 100
        },
        series: [
          {
            data: this.oItem.history.week.y,
            type: 'line',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#FFDD38' // 100% 处的颜色
                }, {
                  offset: 1, color: '#FFFFFF' //  0% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            color: '#FFDD38', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
      }
      let option3 = {
        grid: {
          x: 38,
          y: 10,
          x2: 25,
          y2: 40
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          axisLabel: {
            interval: 2,
            textStyle: {
              color: '#34271d'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          data: this.oItem.history.month.x
        },
        yAxis: {
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#34271d'
            }
          },
          axisTick: { show: false },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#dedcda'
            }
          },
          type: 'value',
          min: 0,
          max: 100
        },
        series: [
          {
            data: this.oItem.history.month.y,
            type: 'line',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#FFDD38' // 100% 处的颜色
                }, {
                  offset: 1, color: '#FFFFFF' //  0% 处的颜色
                }],
                global: false // 缺省为 false
              }
            },
            color: '#FFDD38', // 线条颜色
            smooth: true // 线条平滑
          }
        ]
      }
      if (state) areaChart1.setOption(option1)
      if (this.dateIndex === 0) areaChart2.setOption(option2)
      if (this.dateIndex === 1) areaChart3.setOption(option3)
    },
    // 显示提示语
    onShowHintFn (index, state) {
      this.hintIndex = index
      this.hideState = state
    },
    // 日期切割
    changeDate (date) {
      // eslint-disable-next-line no-useless-escape
      const oldDate = date.replace(' ', ':').replace(/\:/g, '-').split('-')
      this.beginTime = '' + oldDate[1] + '-' + oldDate[2] + ' ' + oldDate[3] + ':' + oldDate[4] + ''
    },
    // 处理单位数字的函数
    showNum (num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    },
    // 题目介绍弹窗状态
    onTopicPopupStateFn (item, state) {
      if (item) this.topicObj = item
      this.topicPopupState = state
    }
  },
  props: {
    oItem: {
      type: Object,
      default: null
    }
  },
  watch: {
    oItem: {
      immediate: true,
      handler (obj) {
        if (obj) {
          this.changeDate(obj.create_time)
          this.allMin = this.showNum(parseInt(obj.time_consuming / 60) % 60)
          this.allSecond = this.showNum(obj.time_consuming % 60)
          this.maxMin = this.showNum(parseInt(obj.duration_max / 60) % 60)
          this.maxSecond = this.showNum(obj.duration_max % 60)
          this.minMin = this.showNum(parseInt(obj.duration_min / 60) % 60)
          this.minSecond = this.showNum(obj.duration_min % 60)
          obj.radar.forEach((item) => {
            const data = {
              text: item.ability_name,
              max: 100
            }
            this.radarScore.push(item.score)
            this.radarName.push(data)
          })
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .content-wrapper
    width: 100%
    height: auto
    padding: 60px 10px 80px
    box-sizing: border-box
    overflow: hidden
    img
      display: block
      width: 100%
      height: 100%
    .title-box
      position: relative
      display: flex
      align-items: center
      .title
        padding-right: 20px
        box-sizing: border-box
        font-size: 32px
        font-weight: 600
        color: #34271D
        span
          font-size: 28px
      .icon
        width: 28px
        height: 28px
      .date-box
        display: flex
        .btn
          width: 100px
          height: auto
          line-height: 40px
          border: 2px solid rgba(52, 39, 29, 0.7)
          border-right: none
          font-size: 20px
          text-align: center
          color: #34271D
          border-radius: 8px 0 0 8px
        .btn:last-child
          border-right: 2px solid rgba(52, 39, 29, 0.7)
          border-left: none
          border-radius: 0 8px 8px 0
        .active
          background: #34271D
          color: #fff
      .hint-box
        position: absolute
        top: 66px
        left: 30px
        z-index: 30
        width: 670px
        height: auto
        padding: 16px
        box-sizing: border-box
        font-size: 26px
        line-height: 38px
        color: #7B756F
        background: #FBFAF9
        border: 2px solid #ECEAE6
        border-radius: 12px
      .hint-box::before
        position: absolute
        top: -32px
        left: 144px
        content: ''
        width: 0
        height: 0
        border: 16px solid
        border-color: transparent transparent #ECEAE6
      .hint-box::after
        position: absolute
        top: -26px
        left: 146px
        content: ''
        width: 0
        height: 0
        border: 14px solid
        border-color: transparent transparent #FBFAF9
    .title-padding
      padding: 0 0 46px 30px
      box-sizing: border-box
    .data-title
      padding: 0 0 20px 30px
      box-sizing: border-box
    .last-title
      justify-content: space-between
      padding: 0 30px
      box-sizing: border-box
      margin-bottom: 24px
    .score-box
      width: 100%
      height: auto
      margin-bottom: 60px
      text-align: center
      .name
        font-size: 36px
        font-weight: 600
        color: #34271d
      .score
        margin: 10px 0
        font-size: 96px
        font-weight: 600
        font-family: Alternate Bold
        color: #34271D
        span
          padding-left: 8px
          box-sizing: border-box
          font-size: 24px
      .hint
        font-size: 24px
        color: #34271D
        font-weight: 400
        span
          padding: 0 10px
          box-sizing: border-box
          font-weight: 600
          color: #D8A800
    .data-box
      position: relative
      width: 100%
      height: auto
      padding-top: 30px
      box-sizing: border-box
      margin-bottom: 20px
      background: #fff
      border: 2px solid #F2F1F0
      border-radius: 26px
      .list-box
        display: flex
        justify-content: space-between
        width: 100%
        height: auto
        padding: 10px 52px 0
        box-sizing: border-box
        &.last
          justify-content: center
          padding-bottom: 40px
          box-sizing: border-box
          .item
            padding: 0 48px
            box-sizing: border-box
        .item
          text-align: center
          font-weight: 400
          .text
            margin-bottom: 16px
            font-size: 24px
            color: rgba(52, 39, 29, 0.7)
          .title
            font-size: 28px
            color: #34271d
      .line-box
        display: flex
        align-items: center
        width: 100%
        height: auto
        padding: 0 30px
        box-sizing: border-box
        margin: 40px 0
        .line
          flex: 1
          height: 2px
          background: #E6DFD9
        .circle
          margin: 0 18px
          width: 6px
          height: 6px
          background: #E6DFD9
          border-radius: 50%
      .charts-box
        position: relative
        width: 100%
        height: 400px
        margin: 0 auto
        .line-ul
          position: absolute
          top: 140px
          left: 0
          width: 100%
          height: auto
          padding: 0 58px 0 100px
          box-sizing: border-box
          .line
            width: 100%
            height: 2px
            background: #DEDCDA
        .text
          position: absolute
          top: 124px
          left: 36px
          font-size: 20px
          font-weight: 600
          color: #7B756F
      .schedule-box
        display: flex
        width: 100%
        height: auto
        margin-top: 24px
        margin-left: 6px
        .item
          flex: 1
          text-align: center
          .charts-box
            height: auto
            .arcbar
              width: 240px
              height: 240px
            .dot
              display: flex
              align-items: center
              justify-content: center
              position: absolute
              top: 50%
              left: 50%
              margin-top: -67px
              margin-left: -67px
              width: 134px
              height: 134px
              background: #FBFAF9
              font-size: 28px
              font-weight: 600
              color: #34271D
              border-radius: 50%
          .name
            margin: 10px 0
            font-size: 24px
            color: rgba(52, 39, 29, 0.7)
          .score
            font-size: 28px
            font-weight: 600
            color: #34271D
            span
              font-family: Alternate Bold
              font-size: 42px
      .expression-box
        width: 100%
        height: auto
        padding: 40px 0 14px 40px
        box-sizing: border-box
        .title
          margin-bottom: 30px
          font-size: 24px
          color: #34271d
        .ul-box
          width: 100%
          height: auto
          .item
            .text-box
              display: flex
              flex-wrap: wrap
              .text
                display: flex
                align-items: center
                margin-bottom: 26px
                padding-right: 30px
                box-sizing: border-box
                font-size: 28px
                font-weight: 400
                color: #34271d
                .circle
                  width: 10px
                  height: 10px
                  background: #FF7133
                  border-radius: 50%
                span
                  padding-left: 12px
                  box-sizing: border-box
            .no-data
              margin-bottom: 26px
              font-size: 28px
              color: #7b756f
            &.last
              padding-top: 12px
              box-sizing: border-box
              .text-box
                .text
                  .circle
                    background: #4B81FF
      .title-label
        display: inline-block
        padding: 4px 20px
        box-sizing: border-box
        margin-bottom: 30px
        line-height: 40px
        background: #FF7133
        font-size: 24px
        font-weight: 600
        color: #fff
        text-align: center
        border-radius: 8px
      .blue-label
        background: #4B81FF
      .topic-box
        width: 100%
        height: auto
        padding: 0 0 10px 30px
        box-sizing: border-box
        .scroll-box
          height: 350px
          overflow: hidden
          .scroll-view
            display: flex
            width: 100%
            height: 100%
            padding-bottom: 20px
            white-space: nowrap
            overflow: hidden
            overflow-x: auto
            -webkit-overflow-scrolling: touch
            .list-ul
              display: flex
              width: auto
              height: auto
              .item
                width: 628px
                height: 310px
                padding: 40px
                box-sizing: border-box
                margin-right: 30px
                background: #FBFAF9
                border: 2px solid #ECEAE6
                border-radius: 20px
                .title
                  width: 100%
                  height: auto
                  font-size: 30px
                  font-weight: 600
                  line-height: 38px
                  color: #34271d
                  white-space: normal !important
                  display: -webkit-box
                  word-break: break-all
                  -webkit-box-orient: vertical
                  -webkit-line-clamp: 2
                  text-overflow: ellipsis
                  overflow: hidden
                .text-box
                  position: relative
                  width: 100%
                  height: 114px
                  margin-top: 12px
                  overflow: hidden
                  white-space: normal !important
                  .text
                    line-height: 40px
                    font-size: 28px
                    color: #7B756F
                  .more
                    position: absolute
                    bottom: 0
                    right: 0
                    width: 120px
                    background: linear-gradient(90deg, rgba(251, 250, 249, 0.8) 0%, #fbfaf9 100%)
                    font-size: 28px
                    color: #D8A800
                    text-align: right
          .scroll-view::-webkit-scrollbar
            width: 0
            height: 0
            color: transparent
      .show-box
        width: 100%
        height: auto
        padding: 0 30px 40px
        box-sizing: border-box
        .text-box
          display: flex
          .text
            font-size: 24px
            text-align: left
            color: #7B756F
          .text:first-child
            flex: 1
            padding-right: 20px
            box-sizing: border-box
        .table
          position: relative
          width: 100%
          height: 266px
          border-bottom: 2px solid #dedcda
          .line
            position: absolute
            left: 0
            bottom: 90px
            width: 100%
            height: 2px
            background: #dedcda
            &.first
              bottom: 180px
          .ul-box
            position: absolute
            bottom: 0
            display: flex
            align-items: flex-end
            justify-content: space-between
            width: 100%
            height: auto
            padding-right: 60px
            box-sizing: border-box
            .item-box
              .item
                width: 48px
                height: 6px
                max-height: 210px
                background: linear-gradient(360deg, #FFDD38 0%, #FFDE95 100%)
                border-radius: 8px 8px 0 0
              .active
                background: linear-gradient(180deg, #FF9262 0%, #FF7133 100%)
        .day-info
          display: flex
          align-items: center
          padding-top: 30px
          box-sizing: border-box
          .day-box
            flex: 1
            display: flex
            align-items: center
            justify-content: space-between
            .num
              flex: 1
              font-size: 20px
              color: #7b756f
          .text
            font-size: 20px
            font-weight: 400
            color: #7b756f
        .hint
          margin-top: 34px
          font-size: 24px
          font-weight: 400
          color: #34271d
          span
            padding: 0 10px
            box-sizing: border-box
            font-weight: 600
            color: #D8A800
      .analysis-box
        padding: 0 30px
        box-sizing: border-box
        margin-bottom: 30px
        .text-box
          display: flex
          justify-content: space-between
          width: 100%
          height: auto
          padding: 16px
          box-sizing: border-box
          background: #F7F5F0
          border-radius: 8px
          .text
            font-size: 24px
            color: #7B756F
            span
              padding-left: 10px
              box-sizing: border-box
            .percent
              font-weight: 600
              color: #D8A800
      &.arcbar-data
        padding: 30px 10px 40px 0
        box-sizing: border-box
        .title-box
          padding-left: 30px
          box-sizing: border-box
    .no-data-show
      .icon
        width: 228px
        height: 240px
    .popup-box-wrapper
      .content
        padding: 50px 50px 60px
        background: #393645
      .name
        margin-bottom: 20px
        font-size: 32px
        font-weight: 500
        line-height: 40px
        white-space: normal !important
        color: #fff
      .text-box
        margin-top: 40px
        font-size: 28px
        font-weight: 400
        line-height: 38px
        overflow: hidden
        word-break: break-all
        white-space: normal !important
        color: rgba(255, 255, 255, 0.85)
</style>
