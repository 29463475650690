<template>
  <div class="detail-wrapper">
    <div
      v-if="callbackObj"
      class="content-center"
    >
      <div class="fixed-bg"></div>
      <div class="return" @click="onBackFn">
        <img src="@/assets/img/return-icon1.png" />
      </div>
      <div class="top-box">
        <img src="@/assets/img/interview-detail-bg.png" />
        <div class="cnt-box">
          <div class="left">
            <div
              class="title"
            >{{ callbackObj.training.title }}</div>
            <div v-if="callbackObj.training.intro" class="desc">{{ callbackObj.training.intro }}</div>
            <div
              v-if="callbackObj.cate"
              :class="!callbackObj.training.intro ? 'type add-margin' : 'type'"
            >职位类型 ｜ {{ callbackObj.cate.name }}</div>
          </div>
          <div class="right">
            <div class="text">参与面试</div>
            <div class="num"><span>{{ callbackObj.peopleNum }}</span>人</div>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="ul-box">
          <div
            v-for="(item, index) in callbackObj.list"
            v-if="callbackObj.list.length > 1 && index === 1 || callbackObj.list.length <= 1 && index === 0"
            class="item"
            :key="index"
          >
            <div class="left">
              <div class="title">{{ item.title }}</div>
              <div class="text-box">
                <span>{{ item.question_count }}道题</span>
                <span class="hot">热度 {{ item.people_num }}</span>
              </div>
            </div>
            <div class="btn" @click="onSkipInterviewPageFn(item.id)">开始面试</div>
            <!--<div class="icon">
              <img v-if="item.level === 1" src="@/assets/img/ai-label-icon1.png" />
              <img v-if="item.level === 2" src="@/assets/img/ai-label-icon2.png" />
            </div>-->
          </div>
        </div>
        <div class="hint-box">
          <div class="title">为保证你全程处于摄录范围，需注意以下问题</div>
          <div class="banner">
            <img src="@/assets/img/aiInterview-hint-icon.png" />
          </div>
          <div class="title-box">
            <div class="line"></div>
            <div class="title">面试前，保证以下设备处于开启状态</div>
            <div class="line"></div>
          </div>
          <div class="list-ul">
            <div class="item">
              <img src="@/assets/img/camera-active-icon.png" />
            </div>
            <div class="item">
              <img src="@/assets/img/voice-active-icon2.png" />
            </div>
            <div class="item">
              <img src="@/assets/img/wifi-active-icon.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      callbackObj: null
    }
  },
  created () {
    const query = this.$route.query
    if (query.id) this.getNewTrainingBeforeFn(query.id)
  },
  computed: {
    ...mapState([
    ])
  },
  methods: {
    ...mapActions([
      'getNewTrainingBefore'
    ]),
    // 获取AI训练过渡页内容
    async getNewTrainingBeforeFn (id) {
      this.callbackObj = await this.getNewTrainingBefore({ id })
    },
    // 跳转ai面试页
    onSkipInterviewPageFn (id) {
      this.$router.push({
        path: '/interview_video',
        query: { id }
      })
    },
    // 返回上一页
    onBackFn () {
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .detail-wrapper
    width: 100%
    height: auto
    img
      display: block
      width: 100%
      height: 100%
    .fixed-bg
      position: fixed
      top: 0
      left: 0
      z-index: -1
      width: 100%
      height: 100%
      background: #F5F8FA
    .top-box
      width: 100%
      height: 692px
      background: linear-gradient(172deg, #DBEBFF 0%, #FFFEED 68%, #F5F8FA 100%)
      .cnt-box
        position: absolute
        top: 160px
        display: flex
        width: 100%
        height: auto
        padding: 0 40px
        box-sizing: border-box
        .left
          flex: 1
          padding-right: 20px
          box-sizing: border-box
          .title
            height: 50px
            font-size: 42px
            color: #34271D
            display: -webkit-box
            -webkit-box-orient: vertical
            -webkit-line-clamp: 2
            word-break: break-all
            overflow: hidden
          .desc
            margin: 12px 0 26px
            padding-right: 80px
            box-sizing: border-box
            font-size: 24px
            color: rgba(123, 117, 111, 0.8)
            display: -webkit-box
            -webkit-box-orient: vertical
            -webkit-line-clamp: 3
            word-break: break-all
            overflow: hidden
          .type
            display: inline-block
            padding: 0 16px
            box-sizing: border-box
            line-height: 42px
            background: #E7E7E7
            font-size: 22px
            font-weight: 600
            text-align: center
            color: #7B756F
            border-radius: 4px
            &.add-margin
              margin-top: 22px
        .right
          text-align: center
          .text
            margin-bottom: 6px
            font-size: 24px
            color: #7B756F
          .num
            font-size: 20px
            font-weight: 600
            color: #34271D
            span
              font-size: 32px
    .info-box
      width: 100%
      height: auto
      padding: 0 60px 80px 40px
      box-sizing: border-box
      margin-top: -190px
      .ul-box
        width: 100%
        height: auto
        margin-bottom: 60px
        .item
          position: relative
          display: flex
          align-items: center
          width: 100%
          height: 160px
          margin-bottom: 30px
          padding: 0 40px
          box-sizing: border-box
          background: #fff
          box-shadow: 0 12px 36px 0 rgba(232, 214, 195, 0.18)
          border-radius: 20px
          border: 2px solid #F0F1F2
          .left
            flex: 1
            padding-right: 20px
            box-sizing: border-box
            .title
              font-size: 32px
              font-weight: 600
              color: #34271D
              display: -webkit-box
              -webkit-box-orient: vertical
              -webkit-line-clamp: 1
              word-break: break-all
              overflow: hidden
            .text-box
              line-height: 42px
              span
                font-size: 24px
                color: #34271D
                &.hot
                  padding-left: 38px
                  box-sizing: border-box
                  color: #7B756F
          .btn
            width: 160px
            height: 60rpx
            line-height: 60px
            background: #FFDD38
            font-size: 24px
            font-weight: 600
            text-align: center
            color: #34271D
            border-radius: 16px
          .icon
            position: absolute
            top: 0
            right: 0
            width: 60px
            height: 62px
      .hint-box
        width: 100%
        height: auto
        .title
          font-size: 24px
          font-weight: 600
          text-align: center
          color: #B3B0AD
        .banner
          width: 100%
          height: 524px
          margin-top: 40px
        .title-box
          display: flex
          align-items: center
          justify-content: center
          margin: 60px 0 40px
          .line
            width: 28px
            height: 2px
            background: #B3B0AD
          .title
            padding: 0 20px
            box-sizing: border-box
        .list-ul
          display: flex
          justify-content: space-between
          padding: 0 62px
          box-sizing: border-box
          .item
            img
              width: 104px
              height: 104px
              margin: 0 auto
</style>
