const state = {
  equipmentType: '', // pc mobile
  companyInfo: {}, // 公司信息
  photoLists: [], // 相册lists
  oItemPhotoInfo: null, // 被选择相册信息
  photoType: 0, // 相册类型 0图片 1视频
  photoClassIndex: 0, // 相册预览分类
  photoCurrentIndex: 0, // 相册预览位置
  interviewTrainLists: [], // 分类面试题目列表
  interviewRecordLists: [] // 面试记录列表
}

export default state
